export const map_exercises_type = {
   "core_swim": "Normal",
   "rest_swim": "Récup.",
   "arms_only": "Bras",
   "legs_only": "Jambes",
   "sprint_swim": "Sprint",
   "exo_swim": "Exo.",
   "warmup": "Échauff.",
}

export const map_swimstrokes = {
   "crawl": "Crawl",
   "breaststroke": "Brasse",
   "backstroke": "Dos",
   "butterfly": "Papillon",
   "freestyle": "Libre",
}
