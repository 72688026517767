import './App.css';
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import axios from "axios";
import background from "./img/waves.svg";
import logo from "./img/swimmer.png";
import logo_text from "./img/logo_text.svg";
import whatsapp from "./img/whatsapp.svg";
import {map_exercises_type, map_swimstrokes} from "./data/fr";

export default function App() {
    const {register, setValue, handleSubmit, watch, formState: {errors}} = useForm();
    const [isSubmit, setSubmit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [trainings, setTrainings] = useState();
    const [trainingsText, setTrainingText] = useState("");

    const onSubmit = async data => {
        await fetchData(data);
    }

    const build_text_message = (trainings) => {
        let trainings_text = "*SWIMGO.CH te propose:*%0a(Échauff.) *50 m Crawl* : Échauffement%0a(Échauff.) *50 m Libre* : Échauffement%0a"
        for (var i = 0; i < trainings.length; i++) {
            trainings_text += `(${map_exercises_type[trainings[i]["exercise"]]}) *${trainings[i]["distance"]} m ${map_swimstrokes[trainings[i]["swim_stroke"]]}* : ${trainings[i]["remarks"]} - ${Math.round(trainings[i]["break_time"]) > 0 ? Math.round(trainings[i]["break_time"]) : 1} min de pause %0a`;
        }
        trainings_text += "(Récup.) *100 m Libre* : Récupération";
        return trainings_text
    }

    useEffect(() => {
        setValue("swim_strokes", ["crawl", "breaststroke", "backstroke", "butterfly"])
    }, [])

    const fetchData = (data) => {
        setLoading(true);

        const customConfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let swimstrokes = ["freestyle"];
        if (data["swim_strokes"] !== false) {
            swimstrokes = data["swim_strokes"];
        }

        let settings = {
            "pool_size": data["pool_size"],
            "training_distance": data["training_distance"],
            "swim_strokes": swimstrokes,
            "training_type": data["training_type"],
            "accessories": data["accessories"]
        }
        axios.post('https://api.swimgo.ch/compute', JSON.stringify(settings), customConfig)
            .then(results => {
                setTrainings(results["data"]);
                setTrainingText(build_text_message(results["data"]))
                setSubmit(true);
                setLoading(false);
            })
    }
    return (
        <div className={"min-h-screen min-w-full bg-white"} style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <div className={"min-h-screen max-w-5xl flex flex-col justify-center text-center mx-auto"}>
                {isLoading === false &&
                    isSubmit === false &&
                    <div>
                        <img className={"w-24 md:w-40 mx-auto mb-2"}
                             src={logo} alt=""/>
                        <img className={"w-40 md:w-80 mx-auto mb-3 md:mb-6"}
                             src={logo_text} alt=""/>
                        <h1 className="text-2xl md:text-4xl text-gray-500 mb-8 px-4 md:px-0">
                            Génère ton entraînement de natation!
                        </h1>
                        <form
                            className={"w-full md:w-1/2 mx-auto text-left bg-white border shadow-lg rounded-xl py-5 px-5"}
                            onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-6">
                                <label className="block text-gray-500 text-xl font-bold mb-2" htmlFor="username">
                                    La taille de la piscine (m)
                                </label>
                                <select type="number" {...register("pool_size", {required: true})}
                                        className="bg-white border border-gray-400 text-gray-900 text-xl rounded-lg focus:ring-gray-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-500 text-xl font-bold mb-2" htmlFor="username">
                                    La distance totale (m)
                                </label>
                                <select type="number" {...register("training_distance", {required: true})}
                                        className="bg-white border border-gray-400 text-gray-900 text-xl rounded-lg focus:ring-gray-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="1000">1000</option>
                                    <option value="1500">1500</option>
                                    <option value="2000">2000</option>
                                    <option value="2500">2500</option>
                                    <option value="3000">3000</option>
                                </select>
                            </div>

                            <div className="mb-6 mt-5">
                                <label className="block text-gray-500 text-xl font-bold mb-2" htmlFor="training_type">
                                    Type d'entrainement
                                </label>
                                <select type="text" {...register("training_type", {required: true})}
                                        className="bg-white border border-gray-400 text-gray-900 text-xl rounded-lg focus:ring-gray-500 focus:border-blue-500 block w-full p-2.5">
                                    <option value="classic">Classique</option>
                                    <option value="competition_prep">Préparation pour compétition</option>
                                    <option value="fun">Fun</option>
                                    <option value="relax">Relax</option>
                                </select>
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 mb-6 mt-5 justify-center">
                                <span className={"flex flex-row gap-2 justify-between md:justify-center"}>
                                    <label className="text-gray-500 text-xl font-bold" htmlFor="crawl">
                                        Crawl
                                    </label>
                                    <input type="checkbox"
                                           className={"w-8 h-8 cursor-pointer"}
                                           value={"crawl"}
                                           {...register("swim_strokes")}
                                    />
                                </span>
                                <span className={"flex flex-row gap-2 justify-between md:justify-center"}>
                                    <label className="block text-gray-500 text-xl font-bold" htmlFor="breaststroke">
                                        Brasse
                                    </label>
                                    <input type="checkbox"
                                           className={"w-8 h-8 cursor-pointer"}
                                           value={"breaststroke"}
                                           {...register("swim_strokes")}
                                    />
                                </span>
                                <span className={"flex flex-row gap-2 justify-between md:justify-center"}>
                                    <label className="block text-gray-500 text-xl font-bold " htmlFor="backstroke">
                                        Dos
                                    </label>
                                    <input type="checkbox"
                                           className={"w-8 h-8 cursor-pointer"}
                                           value={"backstroke"}
                                           {...register("swim_strokes")}
                                    />
                                </span>
                                <span className={"flex flex-row gap-2 justify-between md:justify-center"}>
                                    <label className="block text-gray-500 text-xl font-bold" htmlFor="butterfly">
                                        Papillon
                                    </label>
                                    <input type="checkbox"
                                           className={"w-8 h-8 cursor-pointer"}
                                           value={"butterfly"}
                                           {...register("swim_strokes")}
                                    />
                                </span>
                            </div>
                            <div className="flex flex-col md:flex-row gap-4 mb-6 mt-5 justify-center">
                                <span className={"flex flex-row gap-2 justify-between md:justify-center"}>
                                    <label className="text-gray-500 text-xl font-bold" htmlFor="pull_buoy">
                                        Pull Buoy
                                    </label>
                                    <input type="checkbox"
                                           className={"w-8 h-8 cursor-pointer"}
                                           value={"pull_buoy"}
                                           {...register("accessories")}
                                    />
                                </span>
                                <span className={"flex flex-row gap-2 justify-between md:justify-center"}>
                                    <label className="block text-gray-500 text-xl font-bold" htmlFor="plank">
                                        Planche
                                    </label>
                                    <input type="checkbox"
                                           className={"w-8 h-8 cursor-pointer"}
                                           value={"plank"}
                                           {...register("accessories")}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <button type="submit"
                                        className="text-2xl text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">
                                    Générer
                                </button>
                            </div>
                        </form>

                        <div className={"my-10 text-white"}>
                            <h1 className="text-lg mb-8 px-4 md:px-0">
                                Fabriqué avec ❤️ par des nageurs passionnés.
                            </h1>
                        </div>
                    </div>

                }
                {isLoading === false &&
                    isSubmit === true &&
                    <div className={"my-5 md:mt-20"}>

                        <h1 className="mt-10 sm:mt-0 px-5 text-4xl text-gray-500 mb-8">
                            Voilà ton entraînement!
                        </h1>

                        <div className="mt-8 flex flex-col">
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div
                                        className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3 pl-4 pr-3 text-left text-xl font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                                                >
                                                    Nage
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden sm:table-cell px-3 py-3 text-left text-xl font-medium uppercase tracking-wide text-gray-500"
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3 text-left text-xl font-medium uppercase tracking-wide text-gray-500"
                                                >
                                                    Distance
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3 text-left text-xl font-medium uppercase tracking-wide text-gray-500"
                                                >
                                                    Remarques
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden sm:block px-3 py-3 text-left text-xl font-medium uppercase tracking-wide text-gray-500"
                                                >
                                                    Pause
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white text-left">
                                            <tr key="1-warmup" className={"bg-gray-100"}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                                                    {map_swimstrokes["crawl"]}
                                                </td>
                                                <td className="hidden sm:table-cell whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                                                    {map_exercises_type["warmup"]}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-xl text-gray-500">50 m
                                                </td>
                                                <td className="px-3 py-4 text-xl text-gray-500">Échauffement</td>
                                                <td className="hidden sm:block whitespace-nowrap px-3 py-4 text-xl text-gray-500">{''}</td>
                                            </tr>
                                            <tr key="2-warmup" className={"bg-gray-100"}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                                                    {map_swimstrokes["freestyle"]}
                                                </td>
                                                <td className="hidden sm:table-cell whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                                                    {map_exercises_type["warmup"]}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-xl text-gray-500">50 m
                                                </td>
                                                <td className="px-3 py-4 text-xl text-gray-500">Échauffement</td>
                                                <td className="hidden sm:block whitespace-nowrap px-3 py-4 text-xl text-gray-500">{''}</td>
                                            </tr>
                                            {trainings.map((training) => (
                                                <tr key={training.num}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                                                        {map_swimstrokes[training.swim_stroke]}
                                                    </td>
                                                    <td className="hidden sm:table-cell whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                                                        {map_exercises_type[training.exercise]}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-xl text-gray-500">{training.distance} m</td>
                                                    <td className="px-3 py-4 text-xl text-gray-500">{training.remarks}</td>
                                                    <td className="hidden sm:block whitespace-nowrap px-3 py-4 text-xl text-gray-500">{Math.round(training.break_time) > 0 ? Math.round(training.break_time) : 1}{' '} min</td>
                                                </tr>
                                            ))}
                                            <tr key="2-recovery" className={"bg-gray-100"}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                                                    {map_swimstrokes["freestyle"]}
                                                </td>
                                                <td className="hidden sm:table-cell whitespace-nowrap py-4 pl-4 pr-3 text-xl font-medium text-gray-900 sm:pl-6">
                                                    {map_exercises_type["rest_swim"]}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-xl text-gray-500">100
                                                    m
                                                </td>
                                                <td className="px-3 py-4 text-xl text-gray-500">Récupération</td>
                                                <td className="hidden sm:block whitespace-nowrap px-3 py-4 text-xl text-gray-500">{''}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={"mb-10 sm:mb-0 px-5 md:px-0 flex flex-col md:flex-row gap-2 justify-center mt-10 "}>
                            <button onClick={() => setSubmit(false)}
                                    className="text-xl md:text-2xl text-blue-500 bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center">
                                Un autre?
                            </button>
                            <a
                                className="flex flex-row justify-center gap-2 text-xl md:text-2xl text-white bg-green-500 hover:bg-green-400 hover:text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg w-full sm:w-auto px-5 py-2.5 text-center items-center"
                                href={"whatsapp://send?text=" + trainingsText} data-action="share/whatsapp/share">
                                <span>Partager sur Whatsapp</span>
                                <img className={"w-8 h-8"}
                                     src={whatsapp} alt=""/>
                            </a>
                        </div>

                        <div className={"my-10 text-white"}>
                            <h1 className="text-lg mb-8 px-4 md:px-0">
                                Fabriqué avec ❤️ par des nageurs passionnés.
                            </h1>
                        </div>
                    </div>


                }
                {isLoading === true &&
                    <div className="text-center">
                        <div role="status">
                            <svg className="inline mr-2 w-16 h-16 text-gray-200 animate-spin fill-blue-600"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}
